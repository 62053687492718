import React, { ReactElement } from "react";
import Image from "next/image";
import style from "./style.module.scss";
export default function PhilipsRecalls(): ReactElement {
    return (
        <section className=" flex lg:flex-row flex-col gap-16 lg:mt-32 mt-20">
            <div className={`  ${style["contentStyle"]} mx-auto lg:ml-0`}>
                <h2>
                    Philips Recalls Millions of CPAP, BiLevel PAP, & Ventilator
                    Machines Over Health Risks
                </h2>
                <span></span>
                <p className="mb-4">
                    Philips, a medical device manufacturer, is recalling several
                    sleep apnea machines and ventilators due to a potential
                    cancer risk and other health issues. This recall impacts an
                    estimated 3 to 4 million devices globally. A majority of
                    these are devices intended to assist individuals with sleep
                    apnea, while the remainder are mechanical ventilators.
                </p>
                <p className="mb-4">
                    The concern centers around a polyester-based polyurethane
                    sound abatement foam used in these machines, which, if
                    broken down, can release harmful particles and toxic gases.
                    These emissions could potentially lead to health
                    complications including irritation, nausea, headache,
                    inflammation, vomiting, and even cancer.
                </p>
            </div>
            <div className="lg:w-[281px] lg:h-[360px] w-[241px] h-[320px] relative lg:top-10 mx-auto">
                <Image
                    src="/cpap/assets/images/CpapPicOpt.png"
                    alt="Cpap Pic"
                    layout="fill"
                />
            </div>
        </section>
    );
}
